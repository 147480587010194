const variables = `
  --eds-space-0: 0px;
  --eds-space-xxs: 4px;
  --eds-space-xs: 8px;
  --eds-space-sm: 16px;
  --eds-space-md: 24px;
  --eds-space-lg: 32px;
  --eds-space-xl: 64px;
  --eds-space-xxl: 128px;
`;
export default variables;