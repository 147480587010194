import { useState, useCallback } from 'react';
import type { ChangeEvent, FormEventHandler } from 'react';
import { useFormspark } from '@formspark/use-formspark';
import Button from '../../design-system/button';
import Input from '../../design-system/input';
import Stack from '../../design-system/stack';
import Text from '../../design-system/text';
import Spinner from '../../design-system/spinner';
import styled from 'styled-components';
import tokens from '../../design-system/tokens';
import Alert from '../../design-system/alert';

export interface ContactFormProps {}

const ContactForm = ({}: ContactFormProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [submit, submitting] = useFormspark({
    formId: 'hwTnQnzC',
  });

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    []
  );

  const handleMessageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMessage(event.target.value);
    },
    []
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await submit({ email, message });
    setSubmitted(true);
  };

  let form = !submitting ? (
    <form onSubmit={handleSubmit}>
      <Stack direction="vertical" size="sm" isInline={false}>
        <Input
          label="Email address"
          placeholder=""
          id="email"
          inputMode="email"
          onChange={handleEmailChange}
          autoComplete="email"
          type="email"
          required
        />
        <Input
          label="Comments"
          placeholder=""
          id="message"
          inputMode="text"
          onChange={handleMessageChange}
          disableAutocomplete
          mode="textarea"
          rows={8}
          required
        />
        <Button type="submit">Submit</Button>
      </Stack>
    </form>
  ) : (
    <SpinnerWrapper>
      <Spinner size="lg" />
    </SpinnerWrapper>
  );

  form =
    submitted && !submitting ? (
      <Alert appearance="success" title="Submitted">
        Thank you for your message!
      </Alert>
    ) : (
      form
    );

  return (
    <>
      <Text noMarginTop noMarginBottom>
        Send me a message below
      </Text>
      <Text appearance="subtle" size="xxs" noMarginTop>
        Or reach out to{' '}
        <a href="mailto:contact@figura.finance">contact@figura.finance</a>
      </Text>
      {form}
    </>
  );
};

const SpinnerWrapper = styled.div`
  display: flex;
  margin-top: ${tokens['space-md']};
  justify-content: center;
`;

export default ContactForm;
