import React, { forwardRef } from 'react';

import type { IconDefinition } from '@fortawesome/sharp-solid-svg-icons';
import Stack from '../../stack';

import Wrapper from './components/wrapper';
import Inner from './components/inner';
import Title from './components/title';
import Content from './components/content';
import {
  SemanticAppearance,
  SEMANTIC_APPEARANCE_ICONS,
  SemanticIcon,
} from '../../utils/semantic-appearance';

export type Sizes = 'md' | 'lg' | 'xl';

export interface AlertProps {
  appearance: SemanticAppearance;
  /**
   * Override the default icon.
   */
  icon?: IconDefinition;
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  isInline?: boolean;
  onClick?(): void;
  onMouseEnter?(): void;
  onTouchStart?(): void;
  href?: string;
  size?: Sizes;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      className,
      children,
      title,
      appearance,
      isInline,
      onClick,
      onMouseEnter,
      onTouchStart,
      size,
      icon,
      href,
    },
    ref
  ) => {
    let componentAs: 'section' | 'a' | 'button' = 'section';

    if (href) {
      componentAs = 'a';
    } else if (onClick) {
      componentAs = 'button';
    }

    return (
      <Wrapper
        ref={ref}
        className={className}
        $appearance={appearance}
        $isInline={isInline}
        $size={size}
        onClick={onClick}
        as={componentAs}
        href={href}
        onMouseEnter={onMouseEnter}
        onTouchStart={onTouchStart}
      >
        <Inner>
          <Stack size="xs" alignItems="center">
            <SemanticIcon
              icon={icon || SEMANTIC_APPEARANCE_ICONS[appearance]}
              $appearance={appearance}
            />
            <Title>{title}</Title>
          </Stack>
          {children && <Content>{children}</Content>}
        </Inner>
      </Wrapper>
    );
  }
);

Alert.displayName = 'Alert';

export default Alert;
