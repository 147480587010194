import {
  faCircleCheck,
  faCircleXmark,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/sharp-solid-svg-icons';
import type { IconDefinition } from '@fortawesome/sharp-solid-svg-icons';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tokens from '../tokens';

export type SemanticAppearance =
  | 'success'
  | 'danger'
  | 'warning'
  | 'information';

export const SEMANTIC_APPEARANCE_ICONS: {
  [key in SemanticAppearance]: IconDefinition;
} = {
  success: faCircleCheck,
  danger: faCircleXmark,
  warning: faExclamationTriangle,
  information: faInfoCircle,
} as const;

interface SemanticIconProps {
  $appearance: SemanticAppearance;
}

export const SemanticIcon = styled(FontAwesomeIcon)<SemanticIconProps>`
  color: ${({ $appearance }) => tokens[`color-icon-${$appearance}`]};
  display: block;
`;
