const variables = {
  'size-borderRadius': 'var(--eds-size-borderRadius)',
  'size-borderWidth': 'var(--eds-size-borderWidth)',
  'size-button-height': 'var(--eds-size-button-height)',
  'size-button-height-sm': 'var(--eds-size-button-height-sm)',
  'size-input-height': 'var(--eds-size-input-height)',
  'size-input-paddingInline': 'var(--eds-size-input-paddingInline)',
  'size-input-paddingTop': 'var(--eds-size-input-paddingTop)',
  'size-input-paddingBottom': 'var(--eds-size-input-paddingBottom)',
  'size-icon-xs': 'var(--eds-size-icon-xs)',
  'size-icon-sm': 'var(--eds-size-icon-sm)',
  'size-icon-md': 'var(--eds-size-icon-md)',
  'size-icon-lg': 'var(--eds-size-icon-lg)',
  'size-icon-xl': 'var(--eds-size-icon-xl)',
  'space-0': 'var(--eds-space-0)',
  'space-xxs': 'var(--eds-space-xxs)',
  'space-xs': 'var(--eds-space-xs)',
  'space-sm': 'var(--eds-space-sm)',
  'space-md': 'var(--eds-space-md)',
  'space-lg': 'var(--eds-space-lg)',
  'space-xl': 'var(--eds-space-xl)',
  'space-xxl': 'var(--eds-space-xxl)',
  'typography-fontFamily': 'var(--eds-typography-fontFamily)',
  'typography-lineHeight': 'var(--eds-typography-lineHeight)',
  'typography-lineHeight-heading': 'var(--eds-typography-lineHeight-heading)',
  'typography-weight': 'var(--eds-typography-weight)',
  'typography-weight-heading': 'var(--eds-typography-weight-heading)',
  'typography-weight-button': 'var(--eds-typography-weight-button)',
  'typography-size': 'var(--eds-typography-size)',
  'typography-size-xxxs': 'var(--eds-typography-size-xxxs)',
  'typography-size-xxs': 'var(--eds-typography-size-xxs)',
  'typography-size-xs': 'var(--eds-typography-size-xs)',
  'typography-size-sm': 'var(--eds-typography-size-sm)',
  'typography-size-lg': 'var(--eds-typography-size-lg)',
  'typography-size-xl': 'var(--eds-typography-size-xl)',
  'typography-size-button': 'var(--eds-typography-size-button)',
  'typography-size-button-sm': 'var(--eds-typography-size-button-sm)',
  'typography-size-input': 'var(--eds-typography-size-input)',
  'typography-size-inputLabel-size':
    'var(--eds-typography-size-inputLabel-size)',
  'color-text': 'var(--eds-color-text)',
  'color-text-subtle': 'var(--eds-color-text-subtle)',
  'color-text-disabled': 'var(--eds-color-text-disabled)',
  'color-text-placeholder': 'var(--eds-color-text-placeholder)',
  'color-text-link': 'var(--eds-color-text-link)',
  'color-text-link-hovered': 'var(--eds-color-text-link-hovered)',
  'color-text-link-pressed': 'var(--eds-color-text-link-pressed)',
  'color-text-brand': 'var(--eds-color-text-brand)',
  'color-text-brand-hovered': 'var(--eds-color-text-brand-hovered)',
  'color-text-brand-pressed': 'var(--eds-color-text-brand-pressed)',
  'color-text-accent': 'var(--eds-color-text-accent)',
  'color-text-accent-purple': 'var(--eds-color-text-accent-purple)',
  'color-text-accent-pink': 'var(--eds-color-text-accent-pink)',
  'color-text-inverse': 'var(--eds-color-text-inverse)',
  'color-text-inverse-background': 'var(--eds-color-text-inverse-background)',
  'color-text-success': 'var(--eds-color-text-success)',
  'color-text-success-bold': 'var(--eds-color-text-success-bold)',
  'color-text-danger': 'var(--eds-color-text-danger)',
  'color-text-danger-bold': 'var(--eds-color-text-danger-bold)',
  'color-text-warning': 'var(--eds-color-text-warning)',
  'color-text-warning-bold': 'var(--eds-color-text-warning-bold)',
  'color-text-information': 'var(--eds-color-text-information)',
  'color-text-information-bold': 'var(--eds-color-text-information-bold)',
  'color-background-brand': 'var(--eds-color-background-brand)',
  'color-background-brand-hovered': 'var(--eds-color-background-brand-hovered)',
  'color-background-brand-pressed': 'var(--eds-color-background-brand-pressed)',
  'color-background-accent': 'var(--eds-color-background-accent)',
  'color-background-disabled': 'var(--eds-color-background-disabled)',
  'color-background-success': 'var(--eds-color-background-success)',
  'color-background-success-hovered':
    'var(--eds-color-background-success-hovered)',
  'color-background-success-pressed':
    'var(--eds-color-background-success-pressed)',
  'color-background-danger': 'var(--eds-color-background-danger)',
  'color-background-danger-hovered':
    'var(--eds-color-background-danger-hovered)',
  'color-background-danger-pressed':
    'var(--eds-color-background-danger-pressed)',
  'color-background-warning': 'var(--eds-color-background-warning)',
  'color-background-warning-hovered':
    'var(--eds-color-background-warning-hovered)',
  'color-background-warning-pressed':
    'var(--eds-color-background-warning-pressed)',
  'color-background-information': 'var(--eds-color-background-information)',
  'color-background-information-hovered':
    'var(--eds-color-background-information-hovered)',
  'color-background-information-pressed':
    'var(--eds-color-background-information-pressed)',
  'color-background-input': 'var(--eds-color-background-input)',
  'color-background-neutral': 'var(--eds-color-background-neutral)',
  'color-background-neutral-hovered':
    'var(--eds-color-background-neutral-hovered)',
  'color-background-neutral-pressed':
    'var(--eds-color-background-neutral-pressed)',
  'color-background-neutral-bold': 'var(--eds-color-background-neutral-bold)',
  'color-background-neutral-bold-hovered':
    'var(--eds-color-background-neutral-bold-hovered)',
  'color-background-neutral-bold-pressed':
    'var(--eds-color-background-neutral-bold-pressed)',
  'color-blanket': 'var(--eds-color-blanket)',
  'color-border': 'var(--eds-color-border)',
  'color-border-brand': 'var(--eds-color-border-brand)',
  'color-border-disabled': 'var(--eds-color-border-disabled)',
  'color-border-hovered': 'var(--eds-color-border-hovered)',
  'color-border-accent': 'var(--eds-color-border-accent)',
  'color-border-accent-purple': 'var(--eds-color-border-accent-purple)',
  'color-border-accent-pink': 'var(--eds-color-border-accent-pink)',
  'color-border-success': 'var(--eds-color-border-success)',
  'color-border-danger': 'var(--eds-color-border-danger)',
  'color-border-warning': 'var(--eds-color-border-warning)',
  'color-border-information': 'var(--eds-color-border-information)',
  'color-icon': 'var(--eds-color-icon)',
  'color-icon-hovered': 'var(--eds-color-icon-hovered)',
  'color-icon-pressed': 'var(--eds-color-icon-pressed)',
  'color-icon-inverse': 'var(--eds-color-icon-inverse)',
  'color-icon-inverse-hovered': 'var(--eds-color-icon-inverse-hovered)',
  'color-icon-inverse-pressed': 'var(--eds-color-icon-inverse-pressed)',
  'color-icon-brand': 'var(--eds-color-icon-brand)',
  'color-icon-brand-hovered': 'var(--eds-color-icon-brand-hovered)',
  'color-icon-brand-pressed': 'var(--eds-color-icon-brand-pressed)',
  'color-icon-success': 'var(--eds-color-icon-success)',
  'color-icon-success-hovered': 'var(--eds-color-icon-success-hovered)',
  'color-icon-success-pressed': 'var(--eds-color-icon-success-pressed)',
  'color-icon-danger': 'var(--eds-color-icon-danger)',
  'color-icon-danger-hovered': 'var(--eds-color-icon-danger-hovered)',
  'color-icon-danger-pressed': 'var(--eds-color-icon-danger-pressed)',
  'color-icon-warning': 'var(--eds-color-icon-warning)',
  'color-icon-warning-hovered': 'var(--eds-color-icon-warning-hovered)',
  'color-icon-warning-pressed': 'var(--eds-color-icon-warning-pressed)',
  'color-icon-information': 'var(--eds-color-icon-information)',
  'color-icon-information-hovered': 'var(--eds-color-icon-information-hovered)',
  'color-icon-information-pressed': 'var(--eds-color-icon-information-pressed)',
  'color-elevation-surface': 'var(--eds-color-elevation-surface)',
  'color-elevation-surface-raised': 'var(--eds-color-elevation-surface-raised)',
  'color-elevation-surface-raised-inverse':
    'var(--eds-color-elevation-surface-raised-inverse)',
  'color-elevation-shadow-raised': 'var(--eds-color-elevation-shadow-raised)',
  'color-elevation-shadow-lowered-color':
    'var(--eds-color-elevation-shadow-lowered-color)',
};
export default variables;
