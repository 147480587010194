const variables = `
  --eds-color-text: #171618;
  --eds-color-text-subtle: #757379;
  --eds-color-text-disabled: #8e8d92;
  --eds-color-text-placeholder: #a7a6aa;
  --eds-color-text-link: #4200fe;
  --eds-color-text-link-hovered: #3b00e1;
  --eds-color-text-link-pressed: #3300c4;
  --eds-color-text-brand: #4200fe;
  --eds-color-text-brand-hovered: #3b00e1;
  --eds-color-text-brand-pressed: #3300c4;
  --eds-color-text-accent: #0DBEFF;
  --eds-color-text-accent-purple: #B70CE8;
  --eds-color-text-accent-pink: #ff0078;
  --eds-color-text-inverse: #ffffff;
  --eds-color-text-inverse-background: #ffffff;
  --eds-color-text-success: #008545;
  --eds-color-text-success-bold: #005c2f;
  --eds-color-text-danger: #c20a16;
  --eds-color-text-danger-bold: #ad000c;
  --eds-color-text-warning: #c2520a;
  --eds-color-text-warning-bold: #993d00;
  --eds-color-text-information: #0a50c2;
  --eds-color-text-information-bold: #0042ad;
  --eds-color-background-brand: #4200fe;
  --eds-color-background-brand-hovered: #3b00e1;
  --eds-color-background-brand-pressed: #3300c4;
  --eds-color-background-accent: #0DBEFF;
  --eds-color-background-disabled: #f3f3f3;
  --eds-color-background-success: #baf5d8;
  --eds-color-background-success-hovered: #8eebbe;
  --eds-color-background-success-pressed: #67e0a6;
  --eds-color-background-danger: #ffe8ea;
  --eds-color-background-danger-hovered: #f5babe;
  --eds-color-background-danger-pressed: #eb8e94;
  --eds-color-background-warning: #fff1e8;
  --eds-color-background-warning-hovered: #f5d0ba;
  --eds-color-background-warning-pressed: #ebb28e;
  --eds-color-background-information: #e8f1ff;
  --eds-color-background-information-hovered: #bad0f5;
  --eds-color-background-information-pressed: #8eb2eb;
  --eds-color-background-input: #ffffff;
  --eds-color-background-neutral: #f9f9f9;
  --eds-color-background-neutral-hovered: #f3f3f3;
  --eds-color-background-neutral-pressed: #edeced;
  --eds-color-background-neutral-bold: #edeced;
  --eds-color-background-neutral-bold-hovered: #e6e6e7;
  --eds-color-background-neutral-bold-pressed: #dadadb;
  --eds-color-blanket: #171618b8;
  --eds-color-border: #e6e6e7;
  --eds-color-border-brand: #4200fe;
  --eds-color-border-disabled: #f3f3f3;
  --eds-color-border-hovered: #4200fe;
  --eds-color-border-accent: #0DBEFF;
  --eds-color-border-accent-purple: #B70CE8;
  --eds-color-border-accent-pink: #ff0078;
  --eds-color-border-success: #0ac269;
  --eds-color-border-danger: #d6434d;
  --eds-color-border-warning: #d67c43;
  --eds-color-border-information: #437bd6;
  --eds-color-icon: #b4b3b6;
  --eds-color-icon-hovered: #9b9a9e;
  --eds-color-icon-pressed: #8e8d92;
  --eds-color-icon-inverse: #ffffff;
  --eds-color-icon-inverse-hovered: #f3f3f3;
  --eds-color-icon-inverse-pressed: #e6e6e7;
  --eds-color-icon-brand: #4200fe;
  --eds-color-icon-brand-hovered: #3b00e1;
  --eds-color-icon-brand-pressed: #3300c4;
  --eds-color-icon-success: #0ac269;
  --eds-color-icon-success-hovered: #00ad5a;
  --eds-color-icon-success-pressed: #00994f;
  --eds-color-icon-danger: #d6434d;
  --eds-color-icon-danger-hovered: #c20a16;
  --eds-color-icon-danger-pressed: #ad000c;
  --eds-color-icon-warning: #d67c43;
  --eds-color-icon-warning-hovered: #c2520a;
  --eds-color-icon-warning-pressed: #ad4400;
  --eds-color-icon-information: #437bd6;
  --eds-color-icon-information-hovered: #0a50c2;
  --eds-color-icon-information-pressed: #0042ad;
  --eds-color-elevation-surface: #ffffff;
  --eds-color-elevation-surface-raised: #f9f9f9;
  --eds-color-elevation-surface-raised-inverse: #171618;
  --eds-color-elevation-shadow-raised: 0 0 1px #17161859, 0 0 8px #17161838;
  --eds-color-elevation-shadow-lowered-color: #1716180f;
`;
export default variables;
