const variables = `
  --eds-color-text: #cdcdce;
  --eds-color-text-subtle: #8e8d92;
  --eds-color-text-disabled: #757379;
  --eds-color-text-placeholder: #5d5b61;
  --eds-color-text-link: #a5ccff;
  --eds-color-text-link-hovered: #c7dfff;
  --eds-color-text-link-pressed: #e8f2ff;
  --eds-color-text-brand: #61a7ff;
  --eds-color-text-brand-hovered: #83baff;
  --eds-color-text-brand-pressed: #a5ccff;
  --eds-color-text-accent: #7947ff;
  --eds-color-text-accent-purple: #B70CE8;
  --eds-color-text-accent-pink: #ff0078;
  --eds-color-text-inverse: #171618;
  --eds-color-text-inverse-background: #dadadb;
  --eds-color-text-success: #00ad5a;
  --eds-color-text-success-bold: #00331a;
  --eds-color-text-danger: #e0676f;
  --eds-color-text-danger-bold: #330003;
  --eds-color-text-warning: #d67c43;
  --eds-color-text-warning-bold: #331400;
  --eds-color-text-information: #6796e0;
  --eds-color-text-information-bold: #001433;
  --eds-color-background-brand: #1f7dff;
  --eds-color-background-brand-hovered: #61a7ff;
  --eds-color-background-brand-pressed: #83baff;
  --eds-color-background-accent: #4200fe;
  --eds-color-background-disabled: #2e2d31;
  --eds-color-background-success: #43d68f;
  --eds-color-background-success-hovered: #67e0a6;
  --eds-color-background-success-pressed: #8eebbe;
  --eds-color-background-danger: #e0676f;
  --eds-color-background-danger-hovered: #eb8e94;
  --eds-color-background-danger-pressed: #f5babe;
  --eds-color-background-warning: #d67c43;
  --eds-color-background-warning-hovered: #e09667;
  --eds-color-background-warning-pressed: #ebb28e;
  --eds-color-background-information: #6796e0;
  --eds-color-background-information-hovered: #8eb2eb;
  --eds-color-background-information-pressed: #bad0f5;
  --eds-color-background-input: #171618;
  --eds-color-background-neutral: #222124;
  --eds-color-background-neutral-hovered: #2e2d31;
  --eds-color-background-neutral-pressed: #39383d;
  --eds-color-background-neutral-bold: #39383d;
  --eds-color-background-neutral-bold-hovered: #454349;
  --eds-color-background-neutral-bold-pressed: #514f55;
  --eds-color-blanket: #222124bf;
  --eds-color-border: #39383d;
  --eds-color-border-brand: #61a7ff;
  --eds-color-border-disabled: #514f55;
  --eds-color-border-hovered: #83baff;
  --eds-color-border-accent: #4200fe;
  --eds-color-border-accent-purple: #B70CE8;
  --eds-color-border-accent-pink: #ff0078;
  --eds-color-border-success: #00ad5a;
  --eds-color-border-danger: #e0676f;
  --eds-color-border-warning: #ad4400;
  --eds-color-border-information: #6796e0;
  --eds-color-icon: #69676d;
  --eds-color-icon-hovered: #818086;
  --eds-color-icon-pressed: #8e8d92;
  --eds-color-icon-inverse: #dadadb;
  --eds-color-icon-inverse-hovered: #edeced;
  --eds-color-icon-inverse-pressed: #f3f3f3;
  --eds-color-icon-brand: #61a7ff;
  --eds-color-icon-brand-hovered: #83baff;
  --eds-color-icon-brand-pressed: #a5ccff;
  --eds-color-icon-success: #008545;
  --eds-color-icon-success-hovered: #00994f;
  --eds-color-icon-success-pressed: #00ad5a;
  --eds-color-icon-danger: #ad000c;
  --eds-color-icon-danger-hovered: #c20a16;
  --eds-color-icon-danger-pressed: #d6434d;
  --eds-color-icon-warning: #993d00;
  --eds-color-icon-warning-hovered: #ad4400;
  --eds-color-icon-warning-pressed: #c2520a;
  --eds-color-icon-information: #0042ad;
  --eds-color-icon-information-hovered: #0a50c2;
  --eds-color-icon-information-pressed: #437bd6;
  --eds-color-elevation-surface: #171618;
  --eds-color-elevation-surface-raised: #222124;
  --eds-color-elevation-surface-raised-inverse: #171618;
  --eds-color-elevation-shadow-raised: 0 0 1px #cdcdce73, 0 0 8px #cdcdce21;
  --eds-color-elevation-shadow-lowered-color: #302f334f;
`;
export default variables;
